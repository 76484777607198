import { useState, useEffect } from "react";
import Banner from "../assets/sliders/about-banner.png";
import BlogCard from "../components/BlogCard";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

export default function BlogSection() {
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/blog`)
      .then((response) => {
        setBlog(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the blog!", error);
      });
  }, []);

  if (!blog) {
    return <div className="flex items-center justify-center">Loading...</div>;
  }

  return (
    <>
      <div className="relative bg-gray-900">
        <img src={Banner} alt="Banner" className="w-full h-auto object-cover" />
        <div className="absolute inset-0"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-white">
            <h1 className="text-4xl font-bold sm:text-4xl">Blog</h1>
          </div>
        </div>
      </div>
      {/* Section 2 */}
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {blog.map((blog, index) => {
            const formattedDate = moment(blog.date).format("YYYY-MM-DD");
            return (
              <Link key={index} to={`blogdetails/${blog.slug}`}>
                <BlogCard
                  title={blog.title}
                  description={blog.description}
                  image={`${process.env.REACT_APP_BLOG_IMAGE_PATH}/${blog.featured_image}`}
                />
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
}
