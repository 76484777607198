import React, { useState, useEffect } from "react";
import { ArrowRightCircleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

import Slider1 from "../assets/sliders/slider1.png";
import Slider2 from "../assets/sliders/slider2.png";
import Section1 from "../assets/images/section1.png";

import Granite from "../assets/images/1.png";
import Marble from "../assets/images/2.png";

const images = [
  {
    name: "A Fully Integrated Design",
    src: Slider1,
    description: "Your Ultimate Source for Exquisite Stones Surfaces",
  },
  {
    name: "Your Dream is Your Profession",
    src: Slider2,
    description: "Personalized Solutions Tailored to your needs",
  },
];

const features = [
  {
    name: "Excellent Service",
    icon: ArrowRightCircleIcon,
  },
  {
    name: "Professional Installation",
    icon: ArrowRightCircleIcon,
  },
  {
    name: "Expert Craftsmanship",
    icon: ArrowRightCircleIcon,
  },
  {
    name: "Value Tops Everything",
    icon: ArrowRightCircleIcon,
  },
  {
    name: "Great Variety Allows for Great Choices",
    icon: ArrowRightCircleIcon,
  },
  {
    name: "More Than 10 years of Experience",
    icon: ArrowRightCircleIcon,
  },
];

const people = [
  {
    name: "Granite",
    href: "product/granites",
    src: Granite,
  },
  {
    name: "Marble",
    href: "product/marbles",
    src: Marble,
  },
];

export default function Home() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      <div className="relative overflow-hidden">
        <div className="flex">
          {images.map((image, index) => (
            <div
              key={index}
              className={`relative w-full ${
                index === currentIndex ? "block" : "hidden"
              } sm:flex-shrink-0 sm:w-full lg:w-full`}
            >
              <div className="relative">
                <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
                <img
                  src={image.src}
                  alt={image.name}
                  className="w-full h-auto"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 py-4 px-6">
          <div className="max-w-3xl mx-auto text-center text-white">
            <h2 className="text-2xl sm:text-5xl font-semibold mb-4 text-white-900 animate__animated animate__fadeInLeft">
              {images[currentIndex].name}
            </h2>
            <p className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl text-white animate__animated animate__fadeInLeft">
              {images[currentIndex].description}
            </p>
          </div>
        </div>

        <button
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-2 py-1 rounded-md hover:bg-gray-900"
          onClick={prevSlide}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white px-2 py-1 rounded-md hover:bg-gray-900"
          onClick={nextSlide}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>

      {/* Section 2 */}
      <div className="overflow-hidden bg-white py-10 sm:py-14">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg text-left">
                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  About Company
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600 animate-out slide-out-to-top">
                  We're Leading Company For Marble, Granite And Quartz Stones
                </p>
                <p className="mt-3 text-lg leading-8 text-gray-600 animate-out slide-out-to-top text-justify">
                  At Infinity Stones, we are dedicated to providing the finest
                  quality stones to enhance the beauty and functionality of your
                  living spaces. Our team of skilled artisans and professionals
                  takes great pride in their work, ensuring that every piece of
                  granite or marble we produce meets the highest standards of
                  quality.
                </p>
                <dl className="mt-10 max-w-xl text-base leading-7 text-gray-600 lg:max-w-none">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-8">
                    {features.map((feature, index) => (
                      <div
                        key={feature.name}
                        className={`relative pl-9 md:col-span-1 ${
                          index >= features.length / 1 ? "md:col-start-2" : ""
                        }`}
                      >
                        <dt className="inline font-semibold text-gray-900">
                          <feature.icon
                            aria-hidden="true"
                            className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                          />
                          {feature.name}
                        </dt>
                      </div>
                    ))}
                  </div>
                </dl>
              </div>
            </div>
            <img
              alt="Product screenshot"
              src={Section1}
              width={2432}
              height={1442}
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0 animate-out spin-out delay-150 duration-400"
            />
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="border-t border-gray-200 my-8"></div>
      </div>
      {/* Section 3 */}
      <div className="bg-white py-24 sm:py-32 shadow-inner shadow-2xl">
        <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
          <div className="max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Quality Natural Stones Material
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Enhance your space with our premium natural stone material
            </p>
          </div>
          <ul className="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
            {people.map((person) => (
              <li key={person.name}>
                <div className="flex items-center gap-x-6">
                  <img
                    src={person.src}
                    alt={person.name}
                    className="h-16 w-16 rounded-full"
                  />
                  <div>
                    <Link to={person.href}>
                      <h2 className="text-2xl font-semibold leading-7 tracking-tight text-indigo-900 text-justify">
                        {person.name}
                      </h2>
                    </Link>
                    <Link
                      to={person.href}
                      className="text-sm font-semibold leading-6 text-gray-900"
                    >
                      Learn more <span aria-hidden="true">→</span>
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="border-t border-gray-200 my-8"></div>
      </div>
      {/* Section 4 */}
      <div className="bg-white">
        <div className="mx-auto max-w-full py-10 sm:px-6 sm:py-4 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
            <svg
              viewBox="0 0 1024 1024"
              aria-hidden="true"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            >
              <circle
                r={512}
                cx={512}
                cy={512}
                fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#1f2937" />
                </radialGradient>
              </defs>
            </svg>
            <div className="mx-auto max-w-md text-center lg:max-w-full lg:mx-0 lg:flex-auto lg:py-10 lg:text-left">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl text-center">
                Granite Suppliers To Local And International Markets.
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300 text-justify">
                As distinguished granite suppliers to local and international
                markets, we continuously strive to provide the best raw granite,
                marble, porcelain and quartz materials to execute stunning
                finished products for wholesale and retail. We firmly believe
                that stone is ingrained with geological and historical memories
                that should be captured with care. This is emphasized through
                the earthy and exotic natural stone solutions we supply.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-center lg:mb-0 mb-10 sm:mb-41">
                <Link
                  to="/contact"
                  className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Get Quote
                </Link>
                <Link
                  to="/about"
                  className="text-sm font-semibold leading-6 text-white"
                >
                  Learn more <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
