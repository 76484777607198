import React, { useEffect, useState } from "react";
import Banner from "../assets/sliders/about-banner.png";
import axios from "axios";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import LazyLoad from "react-lazyload";

export default function Blog() {
  const [blog, setBlog] = useState(null);
  let { slug } = useParams();
  useEffect(() => {
    if (slug) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/blogsingle/${slug}`)
        .then((response) => {
          setBlog(response.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the blog!", error);
        });
    }
  }, [slug]);

  if (!blog) {
    return <div className="flex items-center justify-center">Loading...</div>;
  }
  return (
    <>
      {blog.map((item, index) => (
        <div key={index}>
          <div className="relative bg-gray-900">
            <LazyLoad height={200} offset={100}>
              <img
                src={Banner}
                alt="Banner"
                className="w-full h-auto object-cover"
              />
            </LazyLoad>
            <div className="absolute inset-0"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-center text-white">
                <h1 className="text-2xl font-bold sm:text-4xl">{item.title}</h1>
              </div>
            </div>
          </div>

          <div className="bg-gray-100 p-6 space-y-6">
            <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
              <img
                className="w-full h-64 object-cover sm:h-96"
                src={`${process.env.REACT_APP_BLOG_IMAGE_PATH}/${item.featured_image}`}
                alt={item.title}
              />
              <div className="p-6">
                <h2 className="text-2xl font-bold text-gray-800 text-justify">
                  {item.title}
                </h2>
                <p className="text-gray-600 mt-2 text-justify">
                  {parse(item.content)}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
