import React, { useState } from "react";
import Banner from "../assets/sliders/about-banner.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Contact() {
  const [loading, setLoading] = useState(false);
  // Validation schema using Yup
  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required("Please enter your name")
      .matches(
        /^[a-zA-Z\s]*$/,
        "First name can only include alphabetic characters"
      ),
    phoneNumber: Yup.string()
      .required("Please enter your phone number")
      .matches(/^\d+$/, "Phone number must only contain digits")
      .min(10, "Phone number must be greater than 10 characters")
      .max(10, "Phone number must be less than 10 characters"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Please enter your email address"),
    subject: Yup.string(),
    message: Yup.string(),
  });

  // Formik form submission and validation
  const formik = useFormik({
    initialValues: {
      firstName: "",
      phoneNumber: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/contact`,
          values,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.status === "success") {
          toast.success(response.data.msg);
          resetForm();
        } else {
          toast.error(response.data.msg);
        }
      } catch (error) {
        toast.error("Error submitting form.");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="relative bg-gray-900">
        <img src={Banner} alt="Banner" className="w-full h-auto object-cover" />
        <div className="absolute inset-0"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-white">
            <h3 className="text-4xl font-bold sm:text-5xl">Contact Us</h3>
          </div>
        </div>
      </div>
      {/* Section 1 */}
      <div className="isolate bg-white px-6 py-10 sm:py-10 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Get in Touch
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Have A Questions Drop Us Line!
          </p>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="mx-auto mt-16 max-w-xl sm:mt-10"
        >
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-semibold leading-6 text-gray-900 text-left"
              >
                Name <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  autoComplete="given-name"
                  className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                    formik.touched.firstName && formik.errors.firstName
                      ? "border-red-500 focus:ring-red-500"
                      : ""
                  }`}
                  {...formik.getFieldProps("firstName")}
                />
              </div>
              {formik.touched.firstName && formik.errors.firstName ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.firstName}
                </div>
              ) : null}
            </div>
            <div>
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-semibold leading-6 text-gray-900 text-left"
              >
                Phone number <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  autoComplete="tel"
                  className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? "border-red-500 focus:ring-red-500"
                      : ""
                  }`}
                  {...formik.getFieldProps("phoneNumber")}
                />
              </div>
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.phoneNumber}
                </div>
              ) : null}
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-semibold leading-6 text-gray-900 text-left"
              >
                Email <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset ${
                    formik.touched.email && formik.errors.email
                      ? "border-red-500 focus:ring-red-500"
                      : ""
                  } sm:text-sm sm:leading-6`}
                  {...formik.getFieldProps("email")}
                />
              </div>
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
            <div>
              <label
                htmlFor="subject"
                className="block text-sm font-semibold leading-6 text-gray-900 text-left"
              >
                Subject
              </label>
              <div className="mt-1">
                <input
                  id="subject"
                  name="subject"
                  type="text"
                  autoComplete="subject"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  {...formik.getFieldProps("subject")}
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="message"
                className="block text-sm font-semibold leading-6 text-gray-900 text-left"
              >
                Message
              </label>
              <div className="mt-1">
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${
                    formik.touched.message && formik.errors.message
                      ? "border-red-500 focus:ring-red-500"
                      : ""
                  }`}
                  {...formik.getFieldProps("message")}
                />
              </div>
              {formik.touched.message && formik.errors.message ? (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.message}
                </div>
              ) : null}
            </div>
          </div>
          <div className="mt-10">
            <button
              type="submit"
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </div>
        </form>
        <ToastContainer />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="border-t border-gray-200 my-8"></div>
      </div>
      {/* Section 2 */}
      <div className="flex flex-wrap gap-8 justify-center py-10">
        {/* Column 1 */}
        <div className="w-[300px] bg-gray-800 p-6 rounded-lg shadow-md text-center">
          <div className="mb-4">
            {/* Icon */}
            <svg
              className="h-12 w-12 mx-auto text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m11.54 22.351.07.04.028.016a.76.76 0 0 0 .723 0l.028-.015.071-.041a16.975 16.975 0 0 0 1.144-.742 19.58 19.58 0 0 0 2.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 0 0-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 0 0 2.682 2.282 16.975 16.975 0 0 0 1.145.742ZM12 13.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
              />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-2 text-white">
            THE NETHERLANDS
          </h3>
          <p className="text-white text-left">
            Piazza Del Mercato 18, Frascati, Rome 00044, Italy Leksmondhof,
            Amsterdam 1108ET.
          </p>
        </div>

        {/* Column 2 */}
        <div className="w-[300px] bg-white p-6 rounded-lg shadow-md text-center">
          <div className="mb-4">
            {/* Icon */}
            <svg
              className="h-12 w-12 mx-auto text-gray-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
              />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-2">
            Call Us On
          </h3>
          <p className="text-gray-600 text-center">
            <a href="tel: +1 (908) 458-6464"> +1 (908) 458-6464 (Toll free)</a>{" "}
            <br />
            <a href="tel: +1 (740) 971-0286"> +1 (740) 971-0286 </a>
          </p>
        </div>

        {/* Column 3 */}
        <div className="w-[300px] bg-gray-800 p-6 rounded-lg shadow-md text-center">
          <div className="mb-4">
            {/* Icon */}
            <svg
              className="h-12 w-12 mx-auto text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z"
              />
            </svg>
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-2 text-white">
            Email Us On
          </h3>
          <p className="text-white text-center">
            <a href="mailto:info@infinitystoneseu.com">
              info@infinitystoneseu.com
            </a>{" "}
            <br />
            <a href="mailto:support@infinitystoneseu.com">
              support@infinitystoneseu.com
            </a>
          </p>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="border-t border-gray-200 my-8"></div>
      </div>
      {/* Section 3  */}
      <div className="bg-white">
        <div className="mx-auto max-w-full py-10 sm:px-6 sm:py-4 lg:px-8">
          <div className="relative h-[400px]">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190147.15904256116!2d12.437366081538725!3d41.87028445738536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132587a6f1f6ff3d%3A0xcece9452856f48eb!2s00044%20Frascati%2C%20Metropolitan%20City%20of%20Rome%20Capital%2C%20Italy!5e0!3m2!1sen!2sin!4v1721225899214!5m2!1sen!2sin"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Google Map"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}
