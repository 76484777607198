import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Products from "./pages/ProductDetails";
import BlogSection from "./pages/BlogSection";
import Blog from "./pages/Blog";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/product/:slug" element={<Products />} />
        <Route path="/blog" element={<BlogSection />} />
        <Route path="/blog/blogdetails/:slug" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
