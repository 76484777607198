import React from "react";
import { Link } from "react-router-dom";

export default function footer() {
  return (
    <>
      <footer className="bg-gray-800 text-white py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-4">
            <div className="col-span-1 md:col-span-2 min-w-0 w-[360px]">
              <h2 className="text-lg font-semibold mb-2 text-justify">
                About Us
              </h2>
              <p className="text-sm text-left text-justify font-sans">
                We are dedicated to providing the finest quality granite
                products and services to enhance the beauty and functionality of
                your living spaces.
              </p>
            </div>

            <div className="col-span-1 md:col-span-1">
              <h2 className="text-lg font-semibold mb-2 text-justify">
                Quick Links
              </h2>
              <ul className="space-y-1 text-justify font-sans">
                <li>
                  <Link to="/" className="text-sm hover:text-gray-400">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="text-sm hover:text-gray-400">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-sm hover:text-gray-400">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-span-1 md:col-span-1">
              <h2 className="text-lg font-semibold text-left mb-2">Address</h2>
              <div className="text-sm font-sans">
                <p className="mb-2 text-justify">
                  <a href="https://maps.app.goo.gl/E7pbknqMk39axcNL7">
                    Piazza Del Mercato 18, Frascati, Rome 00044, Italy
                    Leksmondhof, Amsterdam 1108ET
                  </a>
                </p>
                <p className="mb-4 text-justify">
                  <strong>Phone:</strong>
                  <br />
                  <a href="tel:+19084586464" className="hover:underline">
                    +1 (908) 458-6464
                  </a>
                  (Toll free)
                  <br />
                  <a href="tel:+17409710286" className="hover:underline">
                    +1 (740) 971-0286
                  </a>
                </p>
                <p className="mb-4 text-justify">
                  <strong>Email:</strong>
                  <br />
                  <a
                    href="mailto:info@infinitystoneseu.com"
                    className="hover:underline"
                  >
                    info@infinitystoneseu.com
                  </a>
                  <br />
                  <a
                    href="mailto:support@infinitystoneseu.com"
                    className="hover:underline"
                  >
                    support@infinitystoneseu.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-6 border-t border-gray-700 pt-4">
            <p className="text-sm text-gray-400">
              &copy; 2024
              <Link to="https://amoriotech.com" className="hover:underline">
                {" "}
                Amorio Technologies
              </Link>
              . All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
