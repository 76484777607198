import React, { useState, useEffect } from "react";
import Banner from "../assets/sliders/about-banner.png";
import Section1 from "../assets/images/section1.png";
import { AcademicCapIcon, EyeIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const features = [
  {
    name: "Mission",
    description:
      "We are committed to responsibly sourcing and providing the highest quality Natural stones and Engineered stones. Our mission is to be a leading industry player, known for sustainable quarrying practices, environmental stewardship, and the delivery of exceptional natural stone products.",
    icon: AcademicCapIcon,
  },
  {
    name: "Vision",
    description:
      "We are committed to responsibly sourcing and providing the highest quality Natural stones and Engineered stones. Our mission is to be a leading industry player, known for sustainable quarrying practices, environmental stewardship, and the delivery of exceptional natural stone products",
    icon: EyeIcon,
  },
];

export default function About() {
  return (
    <>
      <div className="relative bg-gray-900">
        <img src={Banner} alt="Banner" className="w-full h-auto object-cover" />
        <div className="absolute inset-0"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-white">
            <h3 className="text-4xl font-bold sm:text-5xl">About Us</h3>
          </div>
        </div>
      </div>

      {/* Section 2  */}
      <div className="overflow-hidden bg-white py-10 sm:py-14">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg text-left">
                <p className="mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  We Are Best In Stones In Netherlands.
                </p>
                <p className="mt-2 text-lg leading-8 text-gray-600 animate-out slide-out-to-top">
                  About Company
                </p>
                <p className="mt-3 text-md leading-8 text-gray-600 animate-out slide-out-to-top text-justify">
                  Provides the highest quality of materials with the most
                  traditional style. With a copious palette of variety and
                  patterns, Infinity Stones is here to accommodate every
                  customer. Infinity Stones works domestically and
                  Internationally to provide all with the utmost experience and
                  quality products. At Infinity Stones, we are dedicated to
                  providing the finest quality granite products and services to
                  enhance the beauty and functionality of your living spaces.
                  Our team of skilled artisans and professionals takes great
                  pride in their work, ensuring that every piece of granite we
                  produce meets the highest standards of quality.
                  <br />
                  We source our granite and marbles from reputable suppliers,
                  selecting only the finest materials with unique patterns and
                  exquisite natural beauty. Whether you're looking for
                  countertops, flooring, wall cladding, or custom projects, we
                  have a wide range of granite options to suit your specific
                  needs and preferences.
                </p>
              </div>
            </div>
            <img
              alt="Product screenshot"
              src={Section1}
              width={2432}
              height={1442}
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0 animate-out spin-out delay-150 duration-400"
            />
          </div>
        </div>
      </div>

      {/* Section 3 */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="border-t border-gray-200 my-8"></div>
      </div>

      <div className="bg-white mb-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-10 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-left font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                      <feature.icon
                        aria-hidden="true"
                        className="h-6 w-6 text-white"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-left text-justify leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="border-t border-gray-200 my-8"></div>
      </div>
      {/* Section 4 */}
      <div className="bg-white">
        <div className="mx-auto max-w-full py-10 sm:px-6 sm:py-4 lg:px-8">
          <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
            <svg
              viewBox="0 0 1024 1024"
              aria-hidden="true"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            >
              <circle
                r={512}
                cx={512}
                cy={512}
                fill="url(#759c1415-0410-454c-8f7c-9a820de03641)"
                fillOpacity="0.7"
              />
              <defs>
                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                  <stop stopColor="#7775D6" />
                  <stop offset={1} stopColor="#1f2937" />
                </radialGradient>
              </defs>
            </svg>
            <div className="mx-auto max-w-md text-center lg:max-w-full lg:mx-0 lg:flex-auto lg:py-10 lg:text-left">
              <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl text-center">
                Granite Suppliers To Local And International Markets.
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-300 text-justify">
                As distinguished granite suppliers to local and international
                markets, we continuously strive to provide the best raw granite,
                marble, porcelain and quartz materials to execute stunning
                finished products for wholesale and retail. We firmly believe
                that stone is ingrained with geological and historical memories
                that should be captured with care. This is emphasized through
                the earthy and exotic natural stone solutions we supply.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-center lg:mb-0 mb-10 sm:mb-41">
                <Link
                  to="/contact"
                  className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                >
                  Get Quote
                </Link>
                <Link
                  to="/about"
                  className="text-sm font-semibold leading-6 text-white"
                >
                  Learn more <span aria-hidden="true">→</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
