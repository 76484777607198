import React, { useEffect, useState } from "react";
import axios from "axios";
import Banner from "../assets/sliders/about-banner.png";
import { useParams } from "react-router-dom";
import LazyLoad from "react-lazyload";

export default function ProductDetails() {
  const [product, setProduct] = useState(null);
  let { slug } = useParams();

  const getHeading = () => {
    switch (slug) {
      case "granite":
        return "Granite";
      case "marble":
        return "Marble";
      case "quartize":
        return "Quartize";
      case "quartz":
        return "Quartz";
      default:
        return "Product";
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/product/${slug}`)
      .then((response) => {
        setProduct(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the product!", error);
      });
  }, [slug]);

  if (!product) {
    return <div className="flex items-center justify-center">Loading...</div>;
  }

  return (
    <>
      <div className="relative bg-gray-900">
        <img src={Banner} alt="Banner" className="w-full h-auto object-cover" />
        <div className="absolute inset-0"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-white">
            <h3 className="text-4xl font-bold sm:text-5xl">{getHeading()}</h3>
          </div>
        </div>
      </div>
      {/* Product List */}
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <h2 className="sr-only">Products</h2>
          <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
            {product.map((item) => (
              <a
                key={item.product_id}
                href="javascript:void(0);"
                className="group animate-fadeInLeft"
              >
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                  <LazyLoad height={200} offset={100}>
                    <img
                      alt={item.product_name}
                      src={`${process.env.REACT_APP_IMAGE_PATH}/${item.product_image}`}
                      className="h-full w-full object-cover object-center group-hover:opacity-75"
                    />
                  </LazyLoad>
                </div>
                <h3 className="mt-4 text-sm md:text-base lg:text-2xl text-gray-900">
                  {item.product_name}
                </h3>
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
